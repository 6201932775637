import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Overview from "./overview";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "./UTILS/axios";
import UserTickets from "./userTickets";
import CreateTicket from "./createTicket";
import ViewAllTickets from "./viewAllTickets";
import Categories from "./categories";
import AddCategory from "./addCategory";
import Users from "./users";

import Settings from "./settings";
import SetUser from "./setUser";

import QuickLinks from "./knowledgeBase";
import Links from "./info";
import KnowledgeBase from "./knowledgeBase";
import Info from "./info";

function App() {
  const logo = process.env.PUBLIC_URL + "/Stonefield-Logo.png";
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [formDataForgotPassword, setFormDataForgotPassword] = useState({
    email: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [requestPending2, setRequestPending2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
    handleCloseModal();
  };
  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
    handleShowModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleRegister = () => {
    window.location.href = "https://helpdesk.stonefield.co.ke/forms/register";
  };

  const handleLogout = () => {
    setLoggedIn(false);
    Cookies.remove("helpdesk_user_isLoggedIn");
    localStorage.removeItem("helpdesk_user_isAdmin");
    if (!rememberMe) {
      localStorage.removeItem("formData");
    }
    setError("");
    handleShowModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    setError("");
    axiosInstance
      .post("/auth/login", formData, { timeout: 15000 })
      .then((response) => {
        setRequestPending(false);
        setError(response.data.message);
        if (response.data.status === "success") {
          const user = response.data.user;
          Cookies.set("helpdesk_user_isLoggedIn", "true");
          if (user.role === "Administrator" || user.role === "priviledged") {
            setIsAdmin(true);
            localStorage.setItem("helpdesk_user_isAdmin", true);
          } else {
            setIsAdmin(false);
            localStorage.setItem("helpdesk_user_isAdmin", false);
          }

          localStorage.setItem("userId", user.userid);
          localStorage.setItem("email", user.email);
          localStorage.setItem("name", user.username);
          localStorage.setItem("phone", user.phone);
          localStorage.setItem("password", user.password);
          localStorage.setItem("role", user.role);

          if (rememberMe) {
            localStorage.setItem("formData", JSON.stringify(formData));
          } else {
            localStorage.removeItem("formData");
          }

          setLoggedIn(true);
          handleCloseModal();
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setRequestPending(false);
        setError("An error occurred. Please try again.");
      });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setRequestPending2(true);
    setMessage("");

    axiosInstance
      .post("/auth/forgot", formDataForgotPassword, { timeout: 10000 })
      .then((response) => {
        setRequestPending2(false);
        setMessage(response.data.message);
      })
      .catch((error) => {
        setRequestPending2(false);
      });
  };

  useEffect(() => {
    const isLoggedIn = Cookies.get("helpdesk_user_isLoggedIn");
    if (isLoggedIn) {
      const adminStatus =
        localStorage.getItem("helpdesk_user_isAdmin") === "true";
      setIsAdmin(adminStatus);
      setLoggedIn(true);
    } else {
      const savedFormData = localStorage.getItem("formData");
      if (savedFormData) {
        setFormData(JSON.parse(savedFormData));
        setRememberMe(true);
      }
      handleShowModal();
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="layout layout-nav-side">
        <Sidebar isAdmin={isAdmin} />
        <div className="main-container">
          <Navbar onLogout={handleLogout} />
          {loggedIn && (
            <Routes>
              <Route
                path="/tickets"
                element={!isAdmin ? <UserTickets /> : <ViewAllTickets />}
              />
              <Route path="/tickets/create" element={<CreateTicket />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/set" element={<SetUser />} />

              <Route path="/categories" element={<Categories />} />
              <Route path="/Knowledge Base" element={<KnowledgeBase />} />
              <Route path="/info" element={<Info />} />
              <Route path="/info/:title" element={<Info />} />
            </Routes>
          )}
        </div>
      </div>

      <Modal
        backdrop="static"
        keyboard={false}
        show={showModal}
        className="modal-grow"
        onHide={handleCloseModal}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header className="bg-white border-bottom d-flex justify-content-center flex-column">
            <img alt="Image" src={logo} className="avatar mb-2" />
            <h6>Login required!</h6>
          </Modal.Header>
          <Modal.Body>
            {error.length > 1 && (
              <div className="alert alert-secondary" role="alert">
                <FontAwesomeIcon icon="exclamation-circle" /> {error}
              </div>
            )}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">person</i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email address"
                aria-label="Email"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">lock</i>
                </span>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                aria-label="Password"
                value={formData.password}
                onChange={handleInputChange}
                name="password"
              />
            </div>

            <div className="d-flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Remember me
                </label>
              </div>

              <button
                type="button"
                className="btn btn-link ml-auto d-block"
                onClick={handleShowForgotPasswordModal}
              >
                Forgot password?
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-white border-top">
            <button
              type="button"
              className="btn btn-light mr-auto d-block"
              onClick={handleRegister}
            >
              Create account
            </button>

            {requestPending && (
              <button className="ml-auto btn btn-primary" disabled>
                Please wait...
              </button>
            )}
            {!requestPending && (
              <button
                type="submit"
                className="ml-auto btn btn-primary d-block shadow-sm"
              >
                Submit
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showForgotPasswordModal}
        onHide={handleCloseForgotPasswordModal}
      >
        <form onSubmit={handleForgotPassword}>
          <Modal.Header className="bg-white border-bottom d-flex justify-content-center flex-column">
            <img alt="Image" src={logo} className="avatar mb-2" />
            <h6>Login required!</h6>
          </Modal.Header>
          <Modal.Body>
            {message.length > 1 && (
              <div className="alert alert-secondary" role="alert">
                <FontAwesomeIcon icon="exclamation-circle" /> {message}
              </div>
            )}
            <input
              type="email"
              className="form-control"
              name="email"
              value={formDataForgotPassword.email}
              onChange={(e) =>
                setFormDataForgotPassword({
                  ...formDataForgotPassword,
                  email: e.target.value,
                })
              }
              placeholder="Enter email to request password reset"
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              aria-label="Close"
              onClick={handleCloseForgotPasswordModal}
            >
              <span aria-hidden="true">Cancel</span>
            </button>

            <button
              type="submit"
              className="btn btn-secondary"
              disabled={requestPending2}
            >
              {requestPending2 ? "Please wait..." : "Submit"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </BrowserRouter>
  );
}

export default App;
