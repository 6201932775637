import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircle,
  faCircleQuestion,
  faCog,
  faCogs,
  faHome,
  faListAlt,
  faListCheck,
  faPhone,
  faPhoneAlt,
  faStickyNote,
  faTabletAndroid,
  faTicket,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { icon } from "@fortawesome/fontawesome-svg-core";
import axiosInstance from "./UTILS/axios";

const logo = process.env.PUBLIC_URL + "/Stonefield-Logo.png";

const Sidebar = ({ isAdmin }) => {
  const [showContext, setShowContext] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [info, setInfo] = useState([]);
  const [users, setUsers] = useState([]);

  const handleShowContext = (list) => () => {
    setShowContext(true);
    setActiveList(list);
  };

  const handleHideContext = () => {
    setShowContext(false);
    setActiveList([]);
  };

  const menuList = [
    {
      label: "Tickets",
      navigation: "/tickets",
      icon: <FontAwesomeIcon icon={faTicket} />,
      children: isAdmin && [
        { label: "View tickets", navigation: "/tickets" },
        { label: "categories", navigation: "/categories" },
      ],
    },

    isAdmin && {
      label: "Knowledge Base",
      navigation: "/Knowledge base",
      icon: <FontAwesomeIcon icon={faCircleQuestion} />,
    },

    isAdmin && {
      label: "Users",
      navigation: "/users",
      icon: <FontAwesomeIcon icon={faUserAlt} />,
    },

    !isAdmin &&
      info.length > 0 && {
        label: "Knowledge base",
        navigation: "/info",
        icon: <FontAwesomeIcon icon={faCircleQuestion} />,
        children: info.map((info) => ({
          label: info.title,
          navigation: `Info/${info.title}/?id=${info.id}`, // Adjust this to the correct property or URL
        })),
      },

    {
      label: "Settings",
      navigation: "/settings",
      icon: <FontAwesomeIcon icon={faCog} />,
    },
  ].filter(Boolean);

  useEffect(() => {
    axiosInstance
      .get("/info/get", { timeout: 5000 })
      .then((response) => {
        if (response.data) {
          setInfo(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching links:", error);
      });
  }, []);

  return (
    <>
      <div className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top p-0">
        <div className="d-none d-lg-block logo w-100">
          <img alt="Image" src={logo} className="avatar" />
          <span className="text-white">ICT HELPDESK</span>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            aria-controls="navbar-collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-block d-lg-none ml-2">
            <div className="dropdown">
              <a
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img alt="Image" src={logo} className="avatar" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="collapse navbar-collapse flex-column"
          id="navbar-collapse"
        >
          <ul className="navbar-nav d-lg-block accordion" id="accordion">
            {menuList.map((menu, index) =>
              menu.children ? (
                <li className="nav-item" key={index}>
                  <a
                    href="#"
                    className="nav-link"
                    data-toggle="collapse"
                    data-target={`#sub${index}`}
                    aria-expanded="false"
                    aria-controls={`sub${index}`}
                    aria-labelledby="accordion"
                    aria-current="true"
                  >
                    <span>
                      <span className="sidebar-icon">{menu.icon}</span>
                      <span>{menu.label}</span>
                    </span>
                  </a>

                  <div className="collapse" id={`sub${index}`}>
                    <ul className="nav flex-column sub-menu">
                      {menu.children.map((child, childIndex) => (
                        <NavLink
                          to={child.navigation}
                          className="nav-link"
                          key={childIndex}
                        >
                          <span className="sidebar-icon">{child.icon}</span>
                          <span>{child.label}</span>
                        </NavLink>
                      ))}
                    </ul>
                  </div>
                </li>
              ) : (
                <li className="nav-item" key={index}>
                  <NavLink to={menu.navigation} className="nav-link">
                    <span className="sidebar-icon">{menu.icon}</span>
                    <span>{menu.label}</span>
                  </NavLink>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
